import React from 'react'
import Home from './Home'

const App = () => {
	return (
		<div className="App">
			<Home />
		</div>
	)
}

export default App
